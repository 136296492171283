<template>
	<DataTable
		:getQuery="queries.get"
		:createQuery="queries.create"
		:removeQuery="queries.remove"
		:updateQuery="queries.update"
		:headers="headers"
		:createFields="createFields"

		icon="mdi-domain"
		result_name="companies"
		title="company"
		subtitle="List of all companies"
	></DataTable>
</template>
<script>
import DataTable from '@/components/datatable'
export default {
	components: {DataTable},
    data: () => ({
		queries: require('../../../../legacy/unwanted_gql/company.gql'),
		createFields:[
			{name:'name', label: 'Name', icon: 'mdi-domain'},
			{name:'owner', label: 'Owner Name', icon: 'mdi-account'},
			{type: 'tel',name:'phone', label: 'Phone No', icon: 'mdi-phone'},
		],
		headers: [
			{ text: 'Name', min: 15, align: 'left', value: 'name' },
			{ text: 'Owner', align: 'left', value: 'owner' },
			{ text: 'Phone No.', align: 'left', value: 'phone' },
			{ text: 'Depots', align: 'center', value: 'button', button: {
					label: 'view',
					icon: 'mdi-cart',
					color: 'primary',
					text: true,
					show_length: 'depots',
					rounded: true,
					to: {
						name: 'admin-manage-depot',
						params: { key: 'id', value: 'id' }
					}
				},
				sortable: false
			},
      { text: 'Products', align: 'center', value: 'button', button: {
          label: 'view',
          icon: 'visibility',
          color: 'primary',
          text: true,
          show_length: 'storefront.products',
          rounded: true,
          to: {
            name: 'admin-manage-company-products',
            params: { key: 'id', value: 'id' }
          }
        },
        sortable: false
      },
			{ text: 'Date Added', align: 'left', value: 'createdAt' },
			{ text: 'Actions', value: 'action', sortable: false },
		],
    }),
}
</script>
